import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import clientAppImg from '../images/clientapp.png'
// import googlePlay from '../images/google-play.png'

//import loginImg from '../images/000-login.png'

import { IoHomeOutline } from 'react-icons/io5';

import { environment } from '../services/environment';

import { GetNewID } from '../services/data';

import ErrorPage from './errorPage';

import { isValidCNPJ, generateRandomNumber, getDateStampLocal } from '../services/myFunctions';
import Spinner from './spinner';

function StartNow() {
    //const [vendorID, setVendorID] = useState("");
    const [vendorName, setVendorName] = useState("");
    const [vendorCPFCNPJ, setVendorCPFCNPJ] = useState("");

    //const [userID, setUserID] = useState('');
    const [userEmail, setUserEmail] = useState('');
    //const [userName, setUserName] = useState('');
    const [isValid, setIsValid] = useState(true);

    const [errModalVisible, setErrModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');

    const [downloading, setDownloading] = useState(false);

    const vendorIDRef = useRef("");
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const goToHome = () => {
        navigate('/');
    };


    const goToDownloadApp = () => {
        navigate('/downloadApp');
    };

    const handleVendorName = (event) => {
        setMessage("");
        setVendorName(event.target.value);
    };
    const handleVendorCPFCNPJ = (event) => {
        setMessage("");

        const value = event.target.value;
        const cnpj = value.replace(/[^\d]+/g, ''); // Remove caracteres especiais

        setVendorCPFCNPJ(value);

        setIsValid(isValidCNPJ(value));
        // if (cnpj.length === 14) {
        // } else {
        //     setIsValid(true); // Consider input válido enquanto não tiver o número de dígitos correto
        // }
    };
    const handleUserEmail = (event) => {
        setMessage("");
        setUserEmail(event.target.value);
    };

    const formatCNPJ = () => {
        const cnpj = vendorCPFCNPJ.replace(/[^\d]+/g, ''); // Remove caracteres especiais

        setVendorCPFCNPJ(cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5'
        ));
    }

    const saveVendor = () => {

        vendorIDRef.current = GetNewID()

        if (vendorName === "") {
            setMessage('Digite o Nome do Estabelecimento')
            return;
        }

        if (vendorCPFCNPJ === "") {
            setMessage('Digite o CPF ou CNPJ')
            return;
        }

        if (!isValidCNPJ(vendorCPFCNPJ)) {
            setMessage('CNPJ inválido')
            return;
        }

        if (userEmail === "") {
            setMessage('Digite o email do proprietário/responsável.')
            return;
        }

        setDownloading(true);

        const verificationCode = generateRandomNumber().toString();

        const toSave = {
            "vendorID": vendorIDRef.current,
            "vendorName": vendorName,
            "vendorCPFCNPJ": vendorCPFCNPJ,
            "userName": userEmail,
            "userEmail": userEmail,
            "verificationCode": verificationCode,
            "userPwd": verificationCode,
            "creationDate": getDateStampLocal()
        }

        // console.log("RegisterVendor toSave",toSave)
        const controller = new AbortController();
        const signal = controller.signal;

        const tOut = setTimeout(() => {
            controller.abort();
            clearTimeout(tOut);
        }, 20000);

        fetch(environment.centralUrl + 'saveVendorSite',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${environment.preUser}`
                },
                body: JSON.stringify(
                    toSave
                ),
                signal
            })
            .then((response) => {
                if (!response.ok) {
                    // console.log("Error response", response);
                    throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                }

                return response.json()

            }).then((json) => {

                if (json.Saved === "No") {
                    setMessage(json.Message);
                }
                else {

                    localStorage.setItem('vendorUser',
                        JSON.stringify({
                            "vendorID": vendorIDRef.current,
                            "vendorName": vendorName,
                            "vendorCPFCNPJ": vendorCPFCNPJ,
                            "userEmail": userEmail,
                            "userID": json.userID,
                            "userRole": "Admin"
                        }));

                    goToDownloadApp();
                }
            })
            .catch((error) => {
                setErrorMessage(error.message);
                setErrModalVisible(true);
            })
            .finally(() => {
                setDownloading(false);
            })

    };


    const closeErrModal = () => {
        setErrModalVisible(false);
    }

    const retry = () => {
        setErrModalVisible(false);
    }

    return (
        <div>
            {/* Navigation */}
            <nav className="fixed w-full bg-bgTitle">
                <div className="mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex flex-shrink-0 items-center">
                            <img
                                src="https://smartapplications.com.br/images/logoaigarcom2.png"
                                alt="Logo"
                                className="h-6"
                            />
                        </div>


                        <div className="flex items-center">
                            <button
                                onClick={() => goToHome()}
                                className="cursor-pointer text-gray-700 hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md"
                            >
                                <div className='flex flex-row items-center'>
                                    <IoHomeOutline className="h-6 w-6" />
                                    <span className='ml-1 md:ml-3 text-xs md:text-base'>Voltar a página principal</span>

                                </div>
                            </button>


                        </div>

                    </div>
                </div>

            </nav>

            <div className="flex justify-center items-center bg-gray-200 h-screen">
                <div className="mt-10 md:w-1/2">
                    <div className='p-2 md:pl-0'>
                        <p className="text-xl md:text-5xl text-gray-800 mb-2 font-raleway">
                            Comece grátis agora!
                        </p>
                        <p className="text-sm md:text-2xl text-gray-800 mb-2 font-raleway">
                            Experimente o AiGarçom gratuitamente.
                        </p>

                        <header className="flex w-full items-end justify-between">
                            {/* Step 1 */}
                            <div className="flex items-center space-x-3 mt-5">
                                <div className="flex items-center justify-center w-10 h-10 bg-bgTitle text-gray-800 rounded-full">
                                    1
                                </div>
                                <span className="text-xs md:font-medium text-gray-700">Registre seu estabelecimento</span>
                            </div>

                            {/* Divider */}
                            <div className='pb-5'>
                                <div className="border-t-2 border-gray-300 w-0 md:w-56"></div>

                            </div>

                            {/* Step 2 */}
                            <div className="flex items-center space-x-3 md:mr-10">
                                <div className="flex items-center justify-center w-10 h-10 bg-gray-400 
                                text-white rounded-full">
                                    2
                                </div>
                                <div>
                                    <p className="text-xs md:font-medium text-gray-700">Baixe o aplicativo e comece a usar. É muito fácil,</p>
                                    <p className="text-xs md:font-medium text-gray-700">não requer implantação.</p>

                                </div>
                            </div>
                        </header>


                    </div>
                    <div>
                        <div className="bg-white p-5 ">

                            {/* <p className="text-base md:text-xl font-bold text-gray-500 mb-2 font-raleway">
                                1. Registre seu estabelecimento
                            </p> */}
                            <p className="text-xs md:text-xs  text-gray-500 mb-2 font-raleway">
                                Preencha as informações abaixo:
                            </p>
                            <p className="text-xs md:text-xs  text-gray-500 mb-2 font-raleway">
                                A senha inicial temporária será enviada para o email informado.
                            </p>

                            <div>
                                <p className="mt-4 text-base font-bold text-gray-500 font-raleway">
                                    Nome do estabelecimento
                                </p>
                                <input
                                    type="text"
                                    value={vendorName}
                                    onChange={handleVendorName}
                                    className="w-full md:w-[500px] mt-1 p-2 border border-gray-300 
                                        rounded-md shadow-sm focus:outline-none 
                                        focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Digite o nome do estabelecimento..."
                                />
                            </div>

                            <div>
                                <p className="mt-4 text-base font-bold text-gray-500 font-raleway">
                                    CNPJ do estabelecimento
                                </p>
                                <input
                                    type="text"
                                    value={vendorCPFCNPJ}
                                    onChange={handleVendorCPFCNPJ}
                                    className={`w-full md:w-[500px] p-2 border ${isValid ? 'border-gray-300' : 'border-red-500'
                                        } rounded-md focus:outline-none focus:ring-2 ${isValid ? 'focus:ring-blue-500' : 'focus:ring-red-500'
                                        }`}
                                    placeholder="Digite o CNPJ do estabelecimento..."
                                    onBlur={formatCNPJ}
                                />
                            </div>

                            <div>
                                <p className="mt-4 text-base font-bold text-gray-500 font-raleway">
                                    Email do proprietário/responsável
                                </p>
                                <input
                                    type="text"
                                    value={userEmail}
                                    onChange={handleUserEmail}
                                    className="w-full md:w-[500px] p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Digite o email do proprietário/responsável..."
                                />
                            </div>
                            <div >
                                <p className="mt-4 text-base md:text-base text-red-400 font-raleway">
                                    {message}
                                </p>
                            </div>
                            <div className='flex justify-end'>
                                <button onClick={saveVendor}
                                    className={`mt-4 ${message === "" ? 'bg-white-500' : 'bg-red-400'} border border-blue-500 py-2 px-4 rounded`} >
                                    Registrar
                                </button>

                            </div>
                        </div>

                    </div>

                </div>

            </div>
            {downloading && <Spinner />}
            <ErrorPage errorMessage={errorMessage} visible={errModalVisible} closeModal={closeErrModal} retry={retry} />
        </div>

    );
}

export default StartNow;