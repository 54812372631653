
function getTimeStampLocal() {

    const dt = new Date();
    return (
        dt.getFullYear().toString() +
        zeroPad((dt.getMonth() + 1).toString(), 2) +
        zeroPad(dt.getDate().toString(), 2) +
        zeroPad(dt.getHours().toString(), 2) +
        zeroPad(dt.getMinutes().toString(), 2) +
        zeroPad(dt.getSeconds().toString(), 2)
    )
}

function getDateStampLocal() {

    const dt = new Date();
    return (
        dt.getFullYear().toString() +
        zeroPad((dt.getMonth() + 1).toString(), 2) +
        zeroPad(dt.getDate().toString(), 2)
    )
}

const timeStampToDate = (timeStamp) => {
    return timeStamp.substring(6, 8) + "/" +
        timeStamp.substring(4, 6) + "/" +
        timeStamp.substring(0, 4);
};

const timeStampToDateTime = (timeStamp) => {

    if (!timeStamp) {
        return "";
    }

    return timeStamp.substring(6, 8) + "/" +
        timeStamp.substring(4, 6) + "/" +
        timeStamp.substring(0, 4) + " " +
        timeStamp.substring(8, 10) + ":" +
        timeStamp.substring(10, 12);

};

const moneyToDecimal = (value) => {

    if (value === "") {
        return 0.00;
    }

    value = value.replace("R$", "").replace(".", "");
    value = value.replace(",", ".");

    return value;

};

const decimalToMoney = (value) => {

    if (!value) {
        return "R$0,00";
    }

    if (value === "") {
        return "R$0,00";
    }

    if (value === undefined) {
        return "R$0,00";
    }
    try {
        value = value.toFixed(2).toString().replace(".", ",");
        value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    } catch { }


    return "R$" + value;

};

function zeroPad(num, places) { return String(num).padStart(places, '0') };

function getLocalDate() {

    const dt = new Date();
    return (
        zeroPad(dt.getDate().toString(), 2) + "/" +
        zeroPad((dt.getMonth() + 1).toString(), 2) + "/" +
        dt.getFullYear().toString()

    )
}

function getLocalDateTime() {

    const dt = new Date();
    return (
        zeroPad(dt.getDate().toString(), 2) + "/" +
        zeroPad((dt.getMonth() + 1).toString(), 2) + "/" +
        dt.getFullYear().toString() + " " +
        zeroPad(dt.getHours().toString(), 2) + ":" +
        zeroPad(dt.getMinutes().toString(), 2) + ":" +
        zeroPad(dt.getSeconds().toString(), 2)
    )
}

function getLocalTime() {

    const dt = new Date();
    return (
        zeroPad(dt.getHours().toString(), 2) + ":" +
        zeroPad(dt.getMinutes().toString(), 2) + ":" +
        zeroPad(dt.getSeconds().toString(), 2)
    )
}

const generateRandomNumber = () => {
    const min = 1000;
    const max = 9999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
};


const dateStampToDate = (dateStamp) => {

    if (!dateStamp) {
        return "";
    }

    return dateStamp.substring(6, 8) + "/" +
        dateStamp.substring(4, 6) + "/" +
        dateStamp.substring(0, 4);

};

function isValidCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres especiais

    if (cnpj.length !== 14) return false;

    // Elimina CNPJs inválidos conhecidos
    if (/^(\d)\1{13}$/.test(cnpj)) return false;

    // Valida os dígitos verificadores
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) return false;

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) return false;

    return true;
}

const isValidCPF = (cpf) => {
    // Remove caracteres não numéricos
    cpf = cpf.replace(/[^\d]+/g, '');

    // Verifica se o CPF tem 11 dígitos e não é uma sequência repetida
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
        return false;
    }

    // Calcula o primeiro dígito verificador
    let soma = 0;
    for (let i = 0; i < 9; i++) {
        soma += parseInt(cpf[i]) * (10 - i);
    }
    let resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf[9])) {
        return false;
    }

    // Calcula o segundo dígito verificador
    soma = 0;
    for (let i = 0; i < 10; i++) {
        soma += parseInt(cpf[i]) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf[10])) {
        return false;
    }

    return true;
};


function addDays(dateStamp, days) {

    // Convert the dateStamp (yyyymmdd) to a Date object
    let year = parseInt(dateStamp.substring(0, 4), 10);
    let month = parseInt(dateStamp.substring(4, 6), 10) - 1; // Months are 0-indexed in JS
    let day = parseInt(dateStamp.substring(6, 8), 10);

    let date = new Date(year, month, day);
    //console.log("Functions calcDueDate Date", date);

    // Add number of days
    date.setDate(date.getDate() + days);

    // // Handle case where adding a month results in an invalid date (e.g., February 30)
    // if (date.getDate() !== day) {
    //     date.setDate(0); // Go to the last day of the previous month
    // }

    // Format the new date back to yyyymmdd
    const newYear = date.getFullYear();
    const newMonth = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero
    const newDay = ('0' + date.getDate()).slice(-2); // Add leading zero

    return `${newYear}${newMonth}${newDay}`;

}

function addMonth(dateStamp, months) {

    // Convert the dateStamp (yyyymmdd) to a Date object
    let year = parseInt(dateStamp.substring(0, 4), 10);
    let month = parseInt(dateStamp.substring(4, 6), 10) - 1; // Months are 0-indexed in JS
    let day = parseInt(dateStamp.substring(6, 8), 10);

    let date = new Date(year, month, day);

    // Add one month
    date.setMonth(date.getMonth() + months);

    // Handle case where adding a month results in an invalid date (e.g., February 30)
    if (date.getDate() !== day) {
        date.setDate(0); // Go to the last day of the previous month
    }

    // Format the new date back to yyyymmdd
    const newYear = date.getFullYear();
    const newMonth = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero
    const newDay = ('0' + date.getDate()).slice(-2); // Add leading zero

    return `${newYear}${newMonth}${newDay}`;

}


export { timeStampToDate, timeStampToDateTime, moneyToDecimal, decimalToMoney, getLocalDate, getLocalDateTime, getLocalTime, getTimeStampLocal, generateRandomNumber, isValidCNPJ, isValidCPF, getDateStampLocal, addDays, dateStampToDate, addMonth };
