import React from 'react';
import { useNavigate } from 'react-router-dom';
import mainPageImg from '../images/mainpageimg.jpg'
import { IoTimerOutline } from "react-icons/io5";
import { FaRegSmileWink } from "react-icons/fa";

// import { IoReorderThreeOutline, IoClose } from 'react-icons/io5';

function Main({ scrollToMoreDetails }) {
    const navigate = useNavigate();

    const goToStartNow = () => {
        navigate('/startNow');
    };




    return (
        <div className="flex justify-center w-screen">
            <div className="flex flex-col md:w-3/4 md:flex-row space-y-5 md:space-y-0">
                <div>
                    <div>
                        <p className="text-xl font-bold ml-5 md:ml-0 md:text-4xl font-raleway">
                            Aplicativo AiGarçom
                        </p>
                        <p className="ml-5 md:ml-0 mt-2 text-xl md:text-3xl font-raleway">
                            Descubra como otimizar o atendimento
                        </p>
                        <p className="ml-5 md:ml-0 text-xl md:text-3xl font-raleway">
                            do Seu Bar ou Restaurante
                        </p>

                        {/* <p className="pt-2 px-4 text-xl md:text-2xl lg:text-2xl xl:text-2xl font-raleway">
                            Maximize seu faturamento.
                        </p>
                        <p className="px-4 text-xl md:text-2xl lg:text-2xl xl:text-2xl font-raleway">
                            Organize sua operação.
                        </p>
                        <p className="px-4 text-xl md:text-2xl lg:text-2xl xl:text-2xl font-raleway">
                            Ofereça uma experiência excepcional aos seus clientes.
                        </p> */}

                        <div class="grid grid-cols-[10%_1fr] grid-rows-[1fr-1fr] mt-5 ml-3 mr-1">
                            <div class="flex items-start justify-start">
                                <IoTimerOutline class="text-blue-400 w-6 h-6" />
                            </div>
                            <div>
                                <p className="text-left text-gray-700 text-sm font-raleway">
                                    Clientes frustrados, impacientes e desistindo de pedir mais itens por causa da demora no atendimento?
                                </p>

                            </div>

                            <div class="flex justify-start items-start py-2">
                                <FaRegSmileWink class="text-green-300 w-6 h-6" />
                            </div>
                            <div>
                                <p className="py-2 text-left text-gray-700 text-sm font-raleway">
                                    O AiGarçom resolve esse problema com pedidos feitos diretamente no celular do cliente,
                                    sem precisar esperar um garçom ou atendente disponível!
                                </p>
                                <p className="hidden md:block text-left text-gray-700 text-sm font-raleway">
                                    A espera no atendimento pode gerar uma experiência negativa,
                                    fazendo com que muitos clientes desistam da compra e não voltem mais.
                                </p>
                                <p className="text-left hidden md:block text-gray-700 text-sm font-raleway">
                                    Um atendimento ágil e organizado aumenta a satisfação dos clientes e impulsiona seus lucros.
                                </p>
                                <p className="text-left text-gray-700 font-bold text-sm font-raleway">
                                    Não perca tempo, comece a faturar mais agora!
                                </p>

                            </div>
                        </div>

                        {/* <div className="flex flex-col space-y-4 mt-6">
                            <div className="flex items-start">
                                <IoTimerOutline className="mr-2 w-10 h-10 text-gray-500" />
                                <p className="text-left text-gray-700 text-sm font-raleway">
                                    Clientes frustrados, impacientes e desistindo de pedir mais itens por causa da demora no atendimento?
                                </p>
                            </div>
                            <div className="flex items-center">
                                <p className="ml-10 text-left text-gray-700 text-sm font-raleway">
                                    A espera no atendimento pode gerar uma experiência negativa para o cliente,
                                    fazendo com que muitos desistam da compra e não voltem mais.
                                </p>
                            </div>
                            <div className="flex items-start">
                                <FaRegSmileWink className="mr-2 w-11 h-11 text-gray-400" />
                                <p className="text-left text-gray-700 text-sm font-raleway">
                                    O AiGarçom resolve esse problema com pedidos feitos diretamente pelo celular,
                                    sem precisar esperar um garçom ou atendente disponível!
                                </p>
                            </div>
                            <div className="flex items-center">
                                <p className=" text-left text-gray-700 text-sm font-raleway">
                                    Um atendimento ágil e organizado aumenta a satisfação dos clientes e impulsiona seus lucros. Não perca tempo, comece a faturar mais agora!
                                </p>
                            </div>
                        </div> */}

                        <button
                            onClick={goToStartNow}
                            className="ml-6 md:mt-10 bg-blue-500 text-white font-bold py-2 px-4 rounded">
                            Comece grátis!
                        </button>

                        <button onClick={scrollToMoreDetails} className="ml-6 mt-4 bg-white-500 border border-blue-500 py-2 px-4 rounded">
                            Mais informações
                        </button>
                    </div>
                </div>
                <div className="flex justify-center">
                    <img src={mainPageImg} alt="Main" className="p-2 md:p-5 w-full h-auto object-contain" />
                </div>
            </div>
        </div>

    );
}

export default Main;