import React from 'react';
import { IoLogoInstagram } from "react-icons/io";
import { IoLogoFacebook } from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col justify-between items-center">
          {/* Social Media Links */}
          <div className="flex space-x-4">
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-400"
            >
              <IoLogoInstagram />
            </a>
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-400"
            >
              <IoLogoFacebook />
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-400"
            >
              <FaXTwitter />
            </a>
          </div>
        </div>

        {/* Site Links */}
        <div>
          <nav className="mt-4">
            <ul className="flex flex-col space-y-2">
              <li>
                <a href="#home" className="hover:text-gray-400">
                  Home
                </a>
              </li>
              <li>
                <a href="#app" className="hover:text-gray-400">
                  Conheça o aplicativo
                </a>
              </li>
              <li>
                <a href="#pricing" className="hover:text-gray-400">
                  Preço
                </a>
              </li>
              <li>
                <a href="#autoatendimento" className="hover:text-gray-400">
                  App Autoatendimento
                </a>
              </li>
              <li>
                <a href="#representative" className="hover:text-gray-400">
                  Seja um representante
                </a>
              </li>
              <li>
                <a href="#download" className="hover:text-gray-400">
                  Baixar aplicativos
                </a>
              </li>
            </ul>
          </nav>
        </div>

        {/* Footer Note */}
        <div className="text-center mt-6 text-sm text-gray-400">
          © {new Date().getFullYear()} Seu Aplicativo. Todos os direitos reservados.
        </div>
      </div>
    </footer >
  );
};

export default Footer;
