import React, { useState, useRef } from 'react';
import celebrating from '../images/partner4.png'
import { useNavigate } from 'react-router-dom';

function PartnerArea() {
    const navigate = useNavigate();

    const goToStartPartner = () => {
        navigate('/startPartner');
      };

    return (

        <div className="flex justify-center items-center w-screen ">
            <div className="flex flex-col md:flex-row md:ml-10 space-y-5 md:space-y-0">
                <div className="flex flex-col md:w-1/3 p-3 md:p-0">
                    

                    <img src={celebrating} alt="PeopleCelebrating" className="w-full h-auto mt-10" />
                    <p className="text-2xl md:text-4xl font-bold font-raleway mt-5">Transforme seu Tempo Livre em Ganhos Extras</p>
                    <p className="text-xl mt-5 md:text-2xl font-raleway font-normal">Dê o Primeiro Passo Agora Mesmo!</p>
                    <p class="mb-4 font-raleway">Clique no botão abaixo para se cadastrar e começar sua jornada como nosso representante</p>
                    <div className="flex flex-col items-center justify-center">
                    <button
                            onClick={goToStartPartner}
                            className="ml-6 mt-10 bg-blue-500 text-white font-bold py-2 px-4 rounded">
                            Quero ser um representante
                        </button>

                    </div>
                </div>
                <div>
                    <main class="max-w-4xl p-3 md:ml-10 md:ph-0">

                        <p className="text-xl mt-5 md:text-2xl font-raleway">Como Funciona?</p>
                        <p className="mt-1 text-base font-raleway">Estamos buscando representantes autônomos para vender nosso aplicativo,
                            o <span class="font-bold font-raleway">AiGarçom.</span></p>
                        <p className="mt-1 text-base font-raleway">Se você busca liberdade para trabalhar quando e onde quiser,
                            essa é a oportunidade perfeita para você.</p>
                        <ul class="list-disc mt-1 pl-6">
                            <li className="text-base font-raleway"><span class="font-bold">Venda Simples e Direta:</span> Apresente o AiGarçom para bares e restaurantes.</li>
                            <li className="text-base font-raleway"><span class="font-bold">Comissão Garantida:</span> Você ganha 100% da primeira parcela do cliente como comissão.</li>
                            <li className="text-base font-raleway"><span class="font-bold">Flexibilidade Total:</span> Trabalhe no seu próprio ritmo, com liberdade total de horários.</li>
                        </ul>

                        <p className="text-xl mt-5 md:text-2xl font-raleway">Por que Escolher o AiGarçom?</p>
                        <ul class="list-disc mt-1 pl-6">
                            <li className="text-base font-raleway"><span class="font-bold">Inovação que Vende:</span> O aplicativo automatiza pedidos, atendimento, fechamento de contas e muito mais. Uma solução completa que é fácil de apresentar e vender.</li>
                            <li className="text-base font-raleway"><span class="font-bold">Demanda em Alta:</span> Bares e restaurantes estão cada vez mais buscando ferramentas para otimizar seus processos e aumentar a eficiência.</li>
                            <li className="text-base font-raleway"><span class="font-bold">Suporte ao Representante:</span> Fornecemos materiais e treinamentos para ajudar você a conquistar mais clientes.</li>
                        </ul>

                        <p className="text-xl mt-5 md:text-2xl font-raleway">Benefícios para Você</p>
                        <ul class="list-disc mt-1 pl-6">
                            <li className="text-base font-raleway">Ganhos sem limite: Quanto mais vender, mais você ganha.</li>
                            <li className="text-base font-raleway">Não há cobrança ou taxa para se tornar um representante.</li>
                            <li className="text-base font-raleway">Apoio dedicado para tirar suas dúvidas e ajudar você a crescer.</li>
                        </ul>

                        <p className="text-xl mt-5 md:text-2xl font-raleway">Quem Pode Participar?</p>
                        <ul class="list-disc mt-1 pl-6">
                            <li className="text-base font-raleway">
                            Qualquer pessoa com perfil empreendedor e que tenha interesse em gerar renda extra é bem-vinda.
                            Experiência prévia em vendas é um diferencial, mas não é obrigatória.
                            Basta ter energia, vontade de aprender e paixão por conectar soluções inovadoras a quem precisa.
                        </li></ul>

                    </main>

                    {/* <footer class="bg-gray-800 text-white text-center py-4">
                        <p class="text-sm">Dúvidas? Entre em contato com nossa equipe de suporte pelo e-mail <a href="mailto:suporte@aigarcom.com" class="text-blue-400">suporte@aigarcom.com</a>. Estamos aqui para ajudar você a começar com tudo.</p>
                    </footer> */}


                    {/* <div>
                {downloading && <Spinner />}
                <ErrorPage errorMessage={errorMessage} visible={errModalVisible} closeModal={closeErrModal} retry={retry} />
            </div> */}
                </div >
            </div >
        </div>

    );
}

export default PartnerArea;