import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// import { IoReorderThreeOutline, IoClose } from 'react-icons/io5';

function Price() {
    const navigate = useNavigate();

    const goToStartNow = () => {
        navigate('/startNow');
      };
  
    return (
        <div>
            <p className="text-2xl md:text-6xl text-center text-gray-800 mb-2 ">
                Conheça nossos planos
            </p>
            
            <div className="flex flex-col space-y-4 mt-6 md:flex-row md:space-x-4 md:space-y-0 ">
            <button onClick={goToStartNow}>
                <div className="w-[45vh] rounded-lg shadow-lg bg-white">
                    <div className=" px-6 py-4 flex flex-col justify-start ">
                        <div className="font-bold text-xl mb-2 font-raleway text-left">Versão Free</div>
                        <p className="text-left text-gray-700 text-base font-raleway"> 
                            Teste o AiGarçom de forma gratuita.
                        </p>
                        <p className="text-left text-gray-700 text-sm font-raleway">
                            Versão completa grátis para você experimentar a plataforma!
                        </p>
                        <p className="text-left text-gray-700 text-2xl font-raleway">
                            R$ Grátis
                        </p>
                    </div>
                </div>
                </button>
                <div className="w-[45vh] rounded-lg shadow-lg bg-white ">
                    <div className="px-6 py-4">
                        <div className="font-bold text-xl mb-2 font-raleway">Versão Starter</div>
                        <p className="text-gray-700 text-sm font-raleway">
                            Abrindo seu bar ou restaurante? Temos um incentivo especial para você!
                        </p>
                        <p className="text-gray-700 text-xs font-raleway">
                            Até 120 pedidos mês.
                        </p>
                        <p className="text-gray-700 text-2xl font-raleway">
                            R$ 39,00 por mês
                        </p>
                    </div>
                </div>
                <div className="w-[45vh] rounded-lg shadow-lg bg-white">
                    <div className="px-6 py-4">
                        <div className="font-bold text-xl mb-2 font-raleway">Versão Pro</div>
                        <p className="text-gray-700 text-sm font-raleway">
                            Seu restaurante já está a todo vapor? Temos um plano perfeito para você!
                        </p>
                        <p className="text-gray-700 text-xs font-raleway">
                            Acima de 120 pedidos por mês.
                        </p>
                        <p className="text-gray-700 text-2xl font-raleway">
                            R$ 59,00 mês
                        </p>
                    </div>
                </div>
            </div>
            <div className='flex justify-center w-full'>
                <button
                    onClick={goToStartNow}
                    className="ml-6 mt-10 bg-blue-500 text-white font-bold py-2 px-4 rounded">
                    Comece grátis!
                </button>

            </div>
        </div>

    );
}

export default Price;